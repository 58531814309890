import debug from 'debug'

const emailValidatorDebug = debug('auth.change-email')

/**
 * Check if the @param input string meets requirements to be considered
 * a valid email address.
 */
export function doesMeetRequirementsToBeAnEmail(input: string): boolean {
    // Input isn't empty and contains an '@' symbol.
    if (input.length === 0 || !input.includes('@')) {
        emailValidatorDebug(
            `the email ${input} is too short and doesn't include an @ symbol to be a legitimate email.`,
        )
        return false
    }

    // split on the @ symbol and make sure there is at least 1 character each side of it.
    const splitEmailString = input.split('@')
    if (splitEmailString[0].length === 0 || splitEmailString[1].length === 0) {
        emailValidatorDebug(
            `the email ${input} has no strings on either side of the @ symbol.`,
        )
        return false
    }

    return true
}

export function isTheSameEmail(input: string, comparison: string): boolean {
    return input.trim() === comparison.trim()
}
